/*
  See 
    https://tailwindcss.com/docs/functions-and-directives 

  and
    https://tailwindcss.com/docs/using-with-preprocessors#using-post-css-as-your-preprocessor
*/
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'global/fonts.css';

/*
  Extends tailwind's "preflight" CSS reset. See
  https://tailwindcss.com/docs/preflight#extending-preflight
*/
@layer base {
  /* Set a reasonable maximum width for text paragraphs to make them more readable. */
  p {
    @apply max-w-txt text-fl-lg;
  }

  /* Remove captcha badge */
  .grecaptcha-badge {
    visibility: hidden;
  }
}
